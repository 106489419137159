import React from 'react'
import { SlTrophy } from "react-icons/sl";
import acm1 from "../Assets/acm1.jpg"
import acm2 from "../Assets/acm2.jpg"
import acm3 from "../Assets/acm3.jpg"
import acm4 from "../Assets/acm4.jpg"
import { TiRadar } from "react-icons/ti";
function Achievements() {
    const list = [
        {
            img: acm1,
            text: "Quality Brand Awards",
            icon: <SlTrophy />
        },
        {
            img: acm2,
            text: "Quality Brand Award",
            icon: <SlTrophy />
        },
        {
            img: acm3,
            text: "Quality Brand Certificate",
            icon: <SlTrophy />
        },
        {
            img: acm4,
            text: "NCCII Certificate",
            icon: <SlTrophy />
        },
    ]
    const list2 = [
        {
            text: "Bags",
        },
        {
            text: "Caps",
        },
        {
            text: "T-shirts",
        },
        {
            text: "Handicrafts",
        },
        {
            text: "Leather",
        },
        {
            text: "Tabletop Displays",
        },
        {
            text: "Travel",
        },
        {
            text: "Trophies & Medals",
        },
        {
            text: "Watches",
        },
        {
            text: "Others",
        },
    ]
    return (
        <>

            <div className='flex justify-center items-center bg-primary-100'>
                <div className='flex max-w-[1300px] w-full h-[70px] items-center px-5 '>
                    <h1 className='topheading'>Achievements</h1>
                </div>
            </div>
            <div className='flex justify-center items-center bg-thrid-100'>
                <div className='grid grid-cols-1 lg:grid-cols-2  max-w-[1300px] w-full gap-5 p-5 py-10 '>
                    <div className='flex flex-col w-full gap-y-5'>
                        <h1 className='textheading'>Award & Accolades</h1>
                        <div className='grid grid-cols-2 gap-5'>
                            {list.map((item, index) =>
                                <div className='flex flex-col items-center justify-center cursor-pointer hover:bg-primary-100 group relative overflow-hidden'>
                                    <img src={item.img} alt='' className='group-hover:scale-110  duration-500' />
                                    <div className='w-full h-full absolute group-hover:bg-primary-100/70 duration-500 top-0'></div>
                                    <div className='flex-col items-center absolute group-hover:flex group-hover:duration-500 text-white hidden'>{item.text}{item.icon}</div>
                                </div>)}
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-3'>
                        <h1 className='textheading'>Award & Accolades</h1>
                        {list2.map((item, index) =>
                            <div className='flex flex-row '>
                                <TiRadar className='text-primary-100 text-xl' />
                                <h1 className='cursor-pointer hover:text-primary-100'>{item.text}</h1>
                            </div>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Achievements
