import watch1 from "../../Assets/watch1.png"
import { GoPlus } from "react-icons/go";
let watch=[
    {
        img:watch1,
        text:"Watches",
        para:"Id - WAT001",
        icon:<GoPlus/>
    },
]
export default watch
