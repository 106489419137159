import React from 'react'
import InputComponent from "../Typography/InputComponent"
import { FaFacebookF } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
function Contactus() {
    return (
        <>
            <div className='flex justify-center items-center bg-primary-100'>
                <div className='flex max-w-[1300px] w-full h-[70px] items-center px-5 '>
                    <h1 className='topheading'>Contact us</h1>
                </div>
            </div>
            <div className='flex justify-center items-center bg-thrid-100'>
                <div className='grid grid-cols-1 lg:grid-cols-2  max-w-[1300px] w-full gap-5 p-5 py-10 '>
                    <div className='flex flex-col w-full gap-y-5 '>
                        <h1 className='textheading'>Enquiry</h1>
                        <InputComponent name="Name" placeholder="Name" type='text' />
                        <InputComponent name="Email" placeholder="Email" type='text' />
                        <InputComponent name="Subject" placeholder="Subject" type='text' />
                        <div className='flex flex-col gap-y-1'>
                            <h1 className='text-black text-xl'>Message</h1>
                            <textarea className='text-black text-base p-3 outline-none w-max' placeholder='Message' />
                        </div>
                        <button className='self-end text-white bg-primary-100 rounded-md text-lg p-2 px-4'>SEND</button>
                    </div>
                    <div className='flex flex-col w-full gap-y-5 '>
                        <div className='flex flex-col items-start gap-y-5'>
                            <h1 className='textheading'>Context Info</h1>
                            < div className='flex flex-col gap-5 '>
                                <h1 className='subtextheading'>Head Office:BombayGifits</h1>
                                <h1 className='textparagraph'>No 92, Pedariyar Koil Street, 1st Floor, Seven Wells,<br /> Chennai – 600 001. Tamil Nadu, India.<br />Mob +91 94443 09945<br />Mob +91 99405 59443<br />Email - bombay.gifts@gmail.com</h1>
                            </div>
                            <div className='flex flex-col gap-5'>
                                <h1 className='subtextheading'>BombayGifits</h1>
                                <h1 className='textparagraph'>No 16, Jai Bhawani Co-Op Housing Society, Ground<br /> Floor, Khamba Dev Nagar Sion, Dharavi – 400 017.<br /> Mumbai, India.<br />Mob +91 99679 36383<br />Email - gyanesh@bombaygifts.net</h1>
                                <h1 className='textparagraph'>MS.Raj Nandani Thakur<br />Mob +91 9029311001<br />rajnandani.bombaygifts@gmail.com</h1>
                                <h1 className='textparagraph'>Mr.Gyanesh Kumar<br />Mob +91 9967936383<br />gyanesh.bombaygifts@gmail.com</h1>
                            </div>
                            <div className='flex flex-col gap-5'>
                                <h1 className='subtextheading'>Social Link</h1>
                                <div className='flex flex-row gap-x-2'>
                                    <FaFacebookF className='bg-black text-4xl text-gray-400 hover:text-primary-100 cursor-pointer duration-200 p-2' />
                                    <RiLinkedinFill className='bg-black text-4xl text-gray-400 hover:text-primary-100 cursor-pointer duration-200 p-2' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contactus
