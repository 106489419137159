import leather1 from "../../Assets/leather1.jpg"
import leather2 from "../../Assets/leather2.jpg"
import leather3 from "../../Assets/leather3.jpg"
import leather4 from "../../Assets/leather4.jpg"
import leather5 from "../../Assets/leather5.jpg"
import leather6 from "../../Assets/leather6.jpg"
import leather7 from "../../Assets/leather7.jpg"
import leather8 from "../../Assets/leather8.jpg"
import leather9 from "../../Assets/leather9.jpg"
import leather10 from "../../Assets/leather10.jpg"
import leather11 from "../../Assets/leather11.jpg"
import leather12 from "../../Assets/leather12.jpg"
import leather13 from "../../Assets/leather13.jpg"
import leather14 from "../../Assets/leather14.jpg"
import leather15 from "../../Assets/leather15.jpg"
import leather16 from "../../Assets/leather16.jpg"
import leather17 from "../../Assets/leather17.jpg"
import leather18 from "../../Assets/leather18.jpg"
import leather19 from "../../Assets/leather19.jpg"
import leather20 from "../../Assets/leather20.jpg"
import leather21 from "../../Assets/leather21.jpg"
import leather22 from "../../Assets/leather22.jpg"
import leather23 from "../../Assets/leather23.jpg"
import leather24 from "../../Assets/leather24.jpg"
import leather25 from "../../Assets/leather25.jpg"
import leather26 from "../../Assets/leather26.jpg"
import leather27 from "../../Assets/leather27.jpg"
import leather28 from "../../Assets/leather28.jpg"
import leather29 from "../../Assets/leather29.jpg"
import leather30 from "../../Assets/leather30.jpg"
import leather31 from "../../Assets/leather31.jpg"
import leather32 from "../../Assets/leather32.jpg"
import leather33 from "../../Assets/leather33.jpg"
import leather34 from "../../Assets/leather34.jpg"
import leather35 from "../../Assets/leather35.jpg"
import leather36 from "../../Assets/leather36.jpg"
import leather37 from "../../Assets/leather37.jpg"
import leather38 from "../../Assets/leather38.jpg"
import leather39 from "../../Assets/leather39.jpg"
import leather40 from "../../Assets/leather40.jpg"
import { GoPlus } from "react-icons/go";
let leather=[
    {
        img:leather1,
        text:"Leather",
        para:"Id - LEI001",
        icon:<GoPlus/>
    },
    {
        img:leather2,
        text:"Leather",
        para:"Id - LEI002",
        icon:<GoPlus/>
    },
    {
        img:leather3,
        text:"Leather",
        para:"Id - LEI003",
        icon:<GoPlus/>
    },
    {
        img:leather4,
        text:"Leather",
        para:"Id - LEI004",
        icon:<GoPlus/>
    },
    {
        img:leather5,
        text:"Leather",
        para:"Id - LEI005",
        icon:<GoPlus/>
    },
    {
        img:leather6,
        text:"Leather",
        para:"Id - LEI006",
        icon:<GoPlus/>
    },
    {
        img:leather7,
        text:"Leather",
        para:"Id - LEI007",
        icon:<GoPlus/>
    },
    {
        img:leather8,
        text:"Leather",
        para:"Id - LEI008",
        icon:<GoPlus/>
    },
    {
        img:leather9,
        text:"Leather",
        para:"Id - LEI009",
        icon:<GoPlus/>
    },
    {
        img:leather10,
        text:"Leather",
        para:"Id - LEI010",
        icon:<GoPlus/>
    },
    {
        img:leather11,
        text:"Leather",
        para:"Id - LEI011",
        icon:<GoPlus/>
    },
    {
        img:leather12,
        text:"Leather",
        para:"Id - LEI012",
        icon:<GoPlus/>
    },
    {
        img:leather13,
        text:"Leather",
        para:"Id - LEI013",
        icon:<GoPlus/>
    },
    {
        img:leather14,
        text:"Leather",
        para:"Id - LEI014",
        icon:<GoPlus/>
    },
    {
        img:leather15,
        text:"Leather",
        para:"Id - LEI015",
        icon:<GoPlus/>
    },
    {
        img:leather16,
        text:"Leather",
        para:"Id - LEI016",
        icon:<GoPlus/>
    },
    {
        img:leather17,
        text:"Leather",
        para:"Id - LEI017",
        icon:<GoPlus/>
    },
    {
        img:leather18,
        text:"Leather",
        para:"Id - LEI018",
        icon:<GoPlus/>
    },
    {
        img:leather19,
        text:"Leather",
        para:"Id - LEI019",
        icon:<GoPlus/>
    },
    {
        img:leather20,
        text:"Leather",
        para:"Id - LEI020",
        icon:<GoPlus/>
    },
    {
        img:leather21,
        text:"Leather",
        para:"Id - LEI021",
        icon:<GoPlus/>
    },
    {
        img:leather22,
        text:"Leather",
        para:"Id - LEI022",
        icon:<GoPlus/>
    },
    {
        img:leather23,
        text:"Leather",
        para:"Id - LEI023",
        icon:<GoPlus/>
    },
    {
        img:leather24,
        text:"Leather",
        para:"Id - LEI024",
        icon:<GoPlus/>
    },
    {
        img:leather25,
        text:"Leather",
        para:"Id - LEI025",
        icon:<GoPlus/>
    },
    {
        img:leather26,
        text:"Leather",
        para:"Id - LEI026",
        icon:<GoPlus/>
    },
    {
        img:leather27,
        text:"Leather",
        para:"Id - LEI027",
        icon:<GoPlus/>
    },
    {
        img:leather28,
        text:"Leather",
        para:"Id - LEI028",
        icon:<GoPlus/>
    },
    {
        img:leather29,
        text:"Leather",
        para:"Id - LEI029",
        icon:<GoPlus/>
    },
    {
        img:leather30,
        text:"Leather",
        para:"Id - LEI030",
        icon:<GoPlus/>
    },
    {
        img:leather31,
        text:"Leather",
        para:"Id - LEI031",
        icon:<GoPlus/>
    },
    {
        img:leather32,
        text:"Leather",
        para:"Id - LEI032",
        icon:<GoPlus/>
    },
    {
        img:leather33,
        text:"Leather",
        para:"Id - LEI033",
        icon:<GoPlus/>
    },
    {
        img:leather34,
        text:"Leather",
        para:"Id - LEI034",
        icon:<GoPlus/>
    },
    {
        img:leather35,
        text:"Leather",
        para:"Id - LEI035",
        icon:<GoPlus/>
    },
    {
        img:leather36,
        text:"Leather",
        para:"Id - LEI036",
        icon:<GoPlus/>
    },
    {
        img:leather37,
        text:"Leather",
        para:"Id - LEI037",
        icon:<GoPlus/>
    },
    {
        img:leather38,
        text:"Leather",
        para:"Id - LEI038",
        icon:<GoPlus/>
    },
    {
        img:leather39,
        text:"Leather",
        para:"Id - LEI039",
        icon:<GoPlus/>
    },
    {
        img:leather40,
        text:"Leather",
        para:"Id - LEI040",
        icon:<GoPlus/>
    },
]
export default leather
