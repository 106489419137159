import { Label, Input } from '@windmill/react-ui';
import React from 'react'

function InputComponent({ name, type, placeholder, value, OnChangeValue, error,maxLength, disabled }) {
    return (
        <Label className="flex flex-col gap-2">
            <span className='flex flex-row gap-1 text-black text-xl '>{name}</span>
            <Input className='text-black border border-gray-400 rounded-md outline-none p-3' disabled={disabled}
                type={type}
                placeholder={placeholder}
                maxLength={maxLength}
                value={value}
                // onChange={(e) => OnChangeValue(e)}
            />
            {error?.length > 0 ?
                <span className="error">{error}</span> : null}
        </Label>
    )
}

export default InputComponent;