import Trophies1 from "../../Assets/Trophies1.jpg"
import Trophies2 from "../../Assets/Trophies2.jpg"
import Trophies3 from "../../Assets/Trophies3.jpg"
import Trophies4 from "../../Assets/Trophies4.jpg"
import Trophies5 from "../../Assets/Trophies5.jpg"
import Trophies6 from "../../Assets/Trophies6.jpg"
import Trophies7 from "../../Assets/Trophies7.jpg"
import Trophies8 from "../../Assets/Trophies8.jpg"
import Trophies9 from "../../Assets/Trophies9.jpg"
import Trophies10 from "../../Assets/Trophies10.jpg"
import Trophies11 from "../../Assets/Trophies11.jpg"
import Trophies12 from "../../Assets/Trophies12.jpg"
import Trophies13 from "../../Assets/Trophies13.jpg"
import Trophies14 from "../../Assets/Trophies14.jpg"
import Trophies15 from "../../Assets/Trophies15.jpg"
import Trophies16 from "../../Assets/Trophies16.jpg"
import Trophies17 from "../../Assets/Trophies17.jpg"
import Trophies18 from "../../Assets/Trophies18.jpg"
import Trophies19 from "../../Assets/Trophies19.jpg"
import Trophies20 from "../../Assets/Trophies20.jpg"
import Trophies21 from "../../Assets/Trophies21.jpg"
import Trophies22 from "../../Assets/Trophies22.jpg"
import Trophies23 from "../../Assets/Trophies23.jpg"
import Trophies24 from "../../Assets/Trophies24.jpg"
import Trophies25 from "../../Assets/Trophies25.jpg"
import Trophies26 from "../../Assets/Trophies26.jpg"
import Trophies27 from "../../Assets/Trophies27.jpg"
import Trophies28 from "../../Assets/Trophies28.jpg"
import Trophies29 from "../../Assets/Trophies29.jpg"
import Trophies30 from "../../Assets/Trophies30.jpg"
import Trophies31 from "../../Assets/Trophies31.jpg"
import Trophies32 from "../../Assets/Trophies32.jpg"
import Trophies33 from "../../Assets/Trophies33.jpg"
import Trophies34 from "../../Assets/Trophies34.jpg"
import Trophies35 from "../../Assets/Trophies35.jpg"
import Trophies36 from "../../Assets/Trophies36.jpg"
import Trophies37 from "../../Assets/Trophies37.jpg"
import Trophies38 from "../../Assets/Trophies38.jpg"
import Trophies39 from "../../Assets/Trophies39.jpg"
import Trophies40 from "../../Assets/Trophies40.jpg"
import Trophies41 from "../../Assets/Trophies41.jpg"
import { GoPlus } from "react-icons/go";
let Trophies=[
    {
        img:Trophies1,
        text:"Trophies",
        para:"Id - ACT001",
        icon:<GoPlus/>
    },
    {
        img:Trophies2,
        text:"Trophies",
        para:"Id - ACT002",
        icon:<GoPlus/>
    },
    {
        img:Trophies3,
        text:"Trophies",
        para:"Id - ACT003",
        icon:<GoPlus/>
    },
    {
        img:Trophies4,
        text:"Trophies",
        para:"Id - ACT004",
        icon:<GoPlus/>
    },
    {
        img:Trophies5,
        text:"Trophies",
        para:"Id - ACT005",
        icon:<GoPlus/>
    },
    {
        img:Trophies6,
        text:"Trophies",
        para:"Id - ACT006",
        icon:<GoPlus/>
    },
    {
        img:Trophies7,
        text:"Trophies",
        para:"Id - ACT007",
        icon:<GoPlus/>
    },
    {
        img:Trophies8,
        text:"Trophies",
        para:"Id - ACT008",
        icon:<GoPlus/>
    },
    {
        img:Trophies9,
        text:"Trophies",
        para:"Id - ACT009",
        icon:<GoPlus/>
    },
    {
        img:Trophies10,
        text:"Trophies",
        para:"Id - ACT010",
        icon:<GoPlus/>
    },
    {
        img:Trophies11,
        text:"Trophies",
        para:"Id - ACT011",
        icon:<GoPlus/>
    },
    {
        img:Trophies12,
        text:"Trophies",
        para:"Id - ACT012",
        icon:<GoPlus/>
    },
    {
        img:Trophies13,
        text:"Trophies",
        para:"Id - ACT013",
        icon:<GoPlus/>
    },
    {
        img:Trophies14,
        text:"Trophies",
        para:"Id - ACT014",
        icon:<GoPlus/>
    },
    {
        img:Trophies15,
        text:"Trophies",
        para:"Id - ACT015",
        icon:<GoPlus/>
    },
    {
        img:Trophies16,
        text:"Trophies",
        para:"Id - ACT016",
        icon:<GoPlus/>
    },
    {
        img:Trophies17,
        text:"Trophies",
        para:"Id - ACT017",
        icon:<GoPlus/>
    },
    {
        img:Trophies18,
        text:"Trophies",
        para:"Id - ACT018",
        icon:<GoPlus/>
    },
    {
        img:Trophies19,
        text:"Trophies",
        para:"Id - ACT019",
        icon:<GoPlus/>
    },
    {
        img:Trophies20,
        text:"Trophies",
        para:"Id - ACT020",
        icon:<GoPlus/>
    },
    {
        img:Trophies21,
        text:"Trophies",
        para:"Id -  ACT021",
        icon:<GoPlus/>
    },
    {
        img:Trophies22,
        text:"Trophies",
        para:"Id -  ACT022",
        icon:<GoPlus/>
    },
    {
        img:Trophies23,
        text:"Trophies",
        para:"Id -  ACT023",
        icon:<GoPlus/>
    },
    {
        img:Trophies24,
        text:"Trophies",
        para:"Id -  ACT024",
        icon:<GoPlus/>
    },
    {
        img:Trophies25,
        text:"Trophies",
        para:"Id -  ACT025",
        icon:<GoPlus/>
    },
    {
        img:Trophies26,
        text:"Trophies",
        para:"Id -  ACT026",
        icon:<GoPlus/>
    },
    {
        img:Trophies27,
        text:"Trophies",
        para:"Id -  ACT027",
        icon:<GoPlus/>
    },
    {
        img:Trophies28,
        text:"Trophies",
        para:"Id -  ACT028",
        icon:<GoPlus/>
    },
    {
        img:Trophies29,
        text:"Trophies",
        para:"Id - ACT029",
        icon:<GoPlus/>
    },
    {
        img:Trophies30,
        text:"Trophies",
        para:"Id -  ACT030",
        icon:<GoPlus/>
    },
    {
        img:Trophies31,
        text:"Trophies",
        para:"Id - ACT031",
        icon:<GoPlus/>
    },
    {
        img:Trophies32,
        text:"Trophies",
        para:"Id - ACT032",
        icon:<GoPlus/>
    },
    {
        img:Trophies33,
        text:"Trophies",
        para:"Id - ACT033",
        icon:<GoPlus/>
    },
    {
        img:Trophies34,
        text:"Trophies",
        para:"Id - ACT034",
        icon:<GoPlus/>
    },
    {
        img:Trophies35,
        text:"Trophies",
        para:"Id - ACT035",
        icon:<GoPlus/>
    },
    {
        img:Trophies36,
        text:"Trophies",
        para:"Id - ACT036",
        icon:<GoPlus/>
    },
    {
        img:Trophies37,
        text:"Trophies",
        para:"Id - ACT037",
        icon:<GoPlus/>
    },
    {
        img:Trophies38,
        text:"Trophies",
        para:"Id - ACT038",
        icon:<GoPlus/>
    },
    {
        img:Trophies39,
        text:"Trophies",
        para:"Id - ACT039",
        icon:<GoPlus/>
    },
    {
        img:Trophies40,
        text:"Trophies",
        para:"Id - ACT040",
        icon:<GoPlus/>
    },
    {
        img:Trophies41,
        text:"Trophies",
        para:"Id - ACT041",
        icon:<GoPlus/>
    },
   
]
export default Trophies
