import React from "react";
import { useState } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
function Sidebar({ SidebarFunction }) {
    const [Selected, SetSelected] = useState('Home');
    const navigate = useNavigate();
    function ChangePath(para1, para2) {
        navigate(para1)
        SetSelected(para2)
    }
    const list=[
        {
            name:"Home",
            route: "/"
        },
        {
            name:"About Us",
            route: "/Aboutus"
        },
        {
            name:"Products",
            route: "/Products"
        },
        {
            name:"Achievements",
            route: "/Achievements"
        },
        {
            name:"Contact",
            route: "/Contactus"
        },
    ]
    return (
        <div className={`flex flex-row h-screen w-full bg-transparent/60 lg:hidden absolute top-0 right-0 z-50`}>

            <div onClick={() => SidebarFunction(false)} className=" ">
            </div>
            <div className="flex flex-col h-screen flex-1">
                <div className="flex flex-col h-full  items-start justify-start bg-white">
                    <div onClick={() => SidebarFunction(false)} className="flex flex-col items-end w-full h-auto py-7 px-3"><IoMdCloseCircleOutline className="text-5xl" /></div>
                    {list.map((items, index) => <div className={`flex flex-col h-auto w-full ${Selected === items.name ? 'text-blue-800' : 'text-gray-600'} hover:text-blue-800`} onClick={() => ChangePath(items.route, items.name)}>
                        <h1 onClick={() => SidebarFunction(false)} className="text-black text-xl p-5 border-b border-gray-500 w-full">{items.name}</h1>
                    </div>)}
                </div>
            </div>
        </div>
    )

}
export default Sidebar;