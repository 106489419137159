import React, { useState } from 'react'
import All from "../Components/Productslist/All"
import Bag from "../Components/Productslist/Bags"
import cap from "../Components/Productslist/Caps"
import tshirts from "../Components/Productslist/T-shirts"
import handcraft from "../Components/Productslist/Handicrafts"
import leather from "../Components/Productslist/Leather"
import tabletop from "../Components/Productslist/Tabletop"
import travel from "../Components/Productslist/Travel"
import Trophies from "../Components/Productslist/Trophies&Medals"
import watch from "../Components/Productslist/Watches"
import other from "../Components/Productslist/Others"
import { useNavigate } from "react-router-dom";
function Products() {
  const navigate = useNavigate();
  const lists = [
    {
      text: "All",
      list:All
    },
    {
      text: "Bags",
      list:Bag
    },
    {
      text: "Caps",
      list:cap
    },
    {
      text: "T-shirts",
      list:tshirts
    },
    {
      text: "Handicrafts",
      list:handcraft
    },
    {
      text: "Leather",
      list:leather
    },
    {
      text: "Tabletop",
      list:tabletop
    },
    {
      text: "Travel",
      list:travel
    },
    {
      text: "Trophies & Medals",
      list:Trophies
    },
    {
      text: "Watches",
      list:watch
    },
    {
      text: "Others",
      list:other
    },
  ]
  const [open, SetOpen] = useState([...lists[0].list])
  return (
    <>

      <div className='flex justify-center items-center bg-primary-100'>
        <div className='flex max-w-[1300px] w-full h-[70px] items-center  px-5 '>
          <h1 className='topheading'>Products</h1>
        </div>
      </div>
      <div className='flex justify-center items-center '>
        <div className='flex max-w-[1300px] items-center justify-center w-full gap-5 p-5 py-10'>
          <div className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 w-max gap-5'>
            {lists.map((item, index) =>
              <div onClick={()=>SetOpen([...item.list])} className={`flex  hover:bg-gray-900 hover:text-white w-full cursor-pointer ${item.text==open[0].text?"bg-gray-900 text-white":""} justify-center items-center`}>
                <h1 className='text-sm font-semibold whitespace-nowrap text-center w-max p-2'>{item.text}</h1>
              </div>)}
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center '>
        <div className='flex max-w-[1300px]  items-center justify-center w-full gap-5 p-5 py-10'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-max gap-5'>
            {open.map((item, index) =>
              <div onClick={()=>navigate("/Contactus")} className='flex flex-col h-[300px] w-full items-center justify-center border rounded-lg cursor-pointer hover:bg-primary-100 group relative overflow-hidden'>
                <img src={item.img} alt='' className='h-full w-full group-hover:scale-110  duration-500' />
                <div className='w-full h-full absolute group-hover:bg-primary-100/70 duration-500 top-0'></div>
                <div className='flex-col items-center text-center absolute group-hover:flex group-hover:duration-500 text-white hidden gap-2'>
                  <span className='subheading'>{item.text}</span>
                  <span className='text-sm'>{item.para}</span>
                  <span className='heading'>{item.icon}</span></div>
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  )
}

export default Products