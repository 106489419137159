import React from 'react'
import InputComponent from "../Typography/InputComponent"
import { FaFacebookF } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
function Footer() {
  return (
    <>
    <div className='flex flex-col justify-center items-center paragraph bg-gray-700'>
      <div className='max-w-[1300px] w-full py-5'>
        <div className='grid grid-cols-1 lg:grid-cols-3 '>
          <div className='flex flex-col p-5 gap-3'>
            <h1 className='heading'>Subscribe</h1>
            <h1 className='subheading'>Sign Up To Receive Special Offers And Updates</h1>
            <div className='flex flex-row gap-x-2 items-center'>
            <InputComponent button="Subscribe" placeholder="your@email.com" type='text' />
            <button className='bg-primary-100 text-lg p-2'>Subscribe</button>
            </div>
            <h1>Your data will not be shared with others and you can unsubscribe at any time.</h1>
          </div>
          < div className='flex flex-col p-5 gap-3'>
            <h1 className='heading'>Main Address</h1>
            <h1 className='subheading'>Head Office:BombayGifits</h1>
            <h1>No 92, Pedariyar Koil Street, 1st Floor, Seven Wells,<br /> Chennai – 600 001. Tamil Nadu, India.<br />Mob +91 94443 09945<br />Mob +91 99405 59443<br />Email - bombay.gifts@gmail.com</h1>
          </div>
          <div className='flex flex-col p-5 lg:py-16 gap-3'>
            <h1 className='subheading'>BombayGifits</h1>
            <h1>No 16, Jai Bhawani Co-Op Housing Society, Ground<br /> Floor, Khamba Dev Nagar Sion, Dharavi – 400 017.<br /> Mumbai, India.<br />Mob +91 99679 36383<br />Email - gyanesh@bombaygifts.net</h1>
          </div>
        </div>
      </div>
      </div>
      <div className='flex flex-col justify-center items-center text-white bg-secondary-100'>
      <div className='max-w-[1300px] w-full py-5 '>
        <div className='flex flex-col lg:flex-row justify-between items-center  gap-5 p-5'>
          <div className='flex flex-row items-center text-center'>
            <h1>Copyright © 2024 All Rights Reserved.Site Design By Illusionspot Line</h1>
          </div>
          <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row gap-x-2'>
              <FaFacebookF className='hover:text-primary-100 cursor-pointer duration-200' />
              <RiLinkedinFill className='hover:text-primary-100 cursor-pointer duration-200' />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer
