import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Mainpage from "./Mainpage";
import Header from "./Header";
import Footer from "./Footer";
import Contactus from "./Contactus";
import Achievements from "./Achievements";
import Aboutus from "./Aboutus";
import Products from './Products';
const RoutingComponents = () => {
  return (
    <div className=' flex flex-col h-auto w-auto'>
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="/" element={<Mainpage />}></Route>
          <Route path="/Contactus" element={<Contactus />}></Route>
          <Route path="/Achievements" element={<Achievements />}></Route>
          <Route path="/Aboutus" element={<Aboutus />}></Route>
          <Route path="/Products" element={<Products />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  )
}

export default RoutingComponents
