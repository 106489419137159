import other1 from "../../Assets/other1.jpg"
import other2 from "../../Assets/other2.jpg"
import other3 from "../../Assets/other3.jpg"
import other4 from "../../Assets/other4.jpg"
import other5 from "../../Assets/other5.jpg"
import other6 from "../../Assets/other6.jpg"
import other7 from "../../Assets/other7.jpg"
import other8 from "../../Assets/other8.jpg"
import other9 from "../../Assets/other9.jpg"
import other10 from "../../Assets/other10.jpg"
import other11 from "../../Assets/other11.jpg"
import other12 from "../../Assets/other12.jpg"
import other13 from "../../Assets/other13.jpg"
import other14 from "../../Assets/other14.jpg"
import other15 from "../../Assets/other15.jpg"
import other16 from "../../Assets/other16.jpg"
import other17 from "../../Assets/other17.jpg"
import other18 from "../../Assets/other18.jpg"
import other19 from "../../Assets/other19.jpg"
import other20 from "../../Assets/other20.jpg"
import other21 from "../../Assets/other21.jpg"
import other22 from "../../Assets/other22.jpg"
import other23 from "../../Assets/other23.jpg"
import other24 from "../../Assets/other24.jpg"
import other25 from "../../Assets/other25.jpg"
import other26 from "../../Assets/other26.jpg"
import other27 from "../../Assets/other27.jpg"
import other28 from "../../Assets/other28.jpg"
import other29 from "../../Assets/other29.jpg"
import other30 from "../../Assets/other30.jpg"
import other31 from "../../Assets/other31.jpg"
import other32 from "../../Assets/other32.jpg"
import other33 from "../../Assets/other33.jpg"
import other34 from "../../Assets/other34.jpg"
import other35 from "../../Assets/other35.jpg"
import other36 from "../../Assets/other36.jpg"
import other37 from "../../Assets/other37.jpg"
import other38 from "../../Assets/other38.jpg"
import other39 from "../../Assets/other39.jpg"
import other40 from "../../Assets/other40.jpg"
import other41 from "../../Assets/other41.jpg"
import other42 from "../../Assets/other42.jpg"
import other43 from "../../Assets/other43.jpg"
import other44 from "../../Assets/other44.jpg"
import other45 from "../../Assets/other45.jpg"
import other46 from "../../Assets/other46.jpg"
import other47 from "../../Assets/other47.jpg"
import other48 from "../../Assets/other48.jpg"
import other49 from "../../Assets/other49.jpg"
import other50 from "../../Assets/other50.jpg"
import other51 from "../../Assets/other51.jpg"
import other52 from "../../Assets/other52.jpg"
import other53 from "../../Assets/other53.jpg"
import other54 from "../../Assets/other54.jpg"
import other55 from "../../Assets/other55.jpg"
import other56 from "../../Assets/other56.jpg"
import other57 from "../../Assets/other57.jpg"
import other58 from "../../Assets/other58.jpg"
import other59 from "../../Assets/other59.jpg"
import other60 from "../../Assets/other60.jpg"
import other61 from "../../Assets/other61.jpg"
import other62 from "../../Assets/other62.jpg"
import other63 from "../../Assets/other63.jpg"
import other64 from "../../Assets/other64.jpg"
import other65 from "../../Assets/other65.jpg"
import other66 from "../../Assets/other66.jpg"
import other67 from "../../Assets/other67.jpg"
import other68 from "../../Assets/other68.jpg"
import other69 from "../../Assets/other69.jpg"
import other70 from "../../Assets/other70.jpg"
import other71 from "../../Assets/other71.jpg"
import other72 from "../../Assets/other72.jpg"
import other73 from "../../Assets/other73.jpg"
import other74 from "../../Assets/other74.jpg"
import other75 from "../../Assets/other75.jpg"
import other76 from "../../Assets/other76.jpg"
import other77 from "../../Assets/other77.jpg"
import other78 from "../../Assets/other78.jpg"
import other79 from "../../Assets/other79.jpg"
import other80 from "../../Assets/other80.jpg"
import other81 from "../../Assets/other81.jpg"
import other82 from "../../Assets/other82.jpg"
import other83 from "../../Assets/other83.jpg"
import other84 from "../../Assets/other84.jpg"
import other85 from "../../Assets/other85.jpg"
import other86 from "../../Assets/other86.jpg"
import other87 from "../../Assets/other87.jpg"
import other88 from "../../Assets/other88.jpg"
import other89 from "../../Assets/other89.jpg"
import other90 from "../../Assets/other90.jpg"
import other91 from "../../Assets/other91.jpg"
import other92 from "../../Assets/other92.jpg"
import other93 from "../../Assets/other93.jpg"
import other94 from "../../Assets/other94.jpg"
import other95 from "../../Assets/other95.jpg"
import other96 from "../../Assets/other96.jpg"
import other97 from "../../Assets/other97.jpg"
import other98 from "../../Assets/other98.jpg"
import other99 from "../../Assets/other99.jpg"
import other100 from "../../Assets/other100.jpg"
import other101 from "../../Assets/other101.jpg"
import other102 from "../../Assets/other102.jpg"
import other103 from "../../Assets/other103.jpg"
import other104 from "../../Assets/other104.jpg"
import other105 from "../../Assets/other105.jpg"
import other106 from "../../Assets/other106.jpg"
import other107 from "../../Assets/other107.jpg"
import other108 from "../../Assets/other108.jpg"
import other109 from "../../Assets/other109.jpg"
import other110 from "../../Assets/other110.jpg"
import other111 from "../../Assets/other111.jpg"
import other112 from "../../Assets/other112.jpg"
import other113 from "../../Assets/other113.jpg"
import other114 from "../../Assets/other114.jpg"
import other115 from "../../Assets/other115.jpg"
import other116 from "../../Assets/other116.jpg"
import { GoPlus } from "react-icons/go";
let other=[
    {
        img:other1,
        text:"Others",
        para:"Id - GIF001",
        icon:<GoPlus/>
    },
    {
        img:other2,
        text:"Others",
        para:"Id - GIF002",
        icon:<GoPlus/>
    },
    {
        img:other3,
        text:"Others",
        para:"Id - GIF003",
        icon:<GoPlus/>
    },
    {
        img:other4,
        text:"Others",
        para:"Id - GIF004",
        icon:<GoPlus/>
    },
    {
        img:other5,
        text:"Others",
        para:"Id - GIF005",
        icon:<GoPlus/>
    },
    {
        img:other6,
        text:"Others",
        para:"Id - GIF006",
        icon:<GoPlus/>
    },
    {
        img:other7,
        text:"Others",
        para:"Id - GIF007",
        icon:<GoPlus/>
    },
    {
        img:other8,
        text:"Others",
        para:"Id - GIF008",
        icon:<GoPlus/>
    },
    {
        img:other9,
        text:"Others",
        para:"Id - GIF009",
        icon:<GoPlus/>
    },
    {
        img:other10,
        text:"Others",
        para:"Id - GIF010",
        icon:<GoPlus/>
    },
    {
        img:other11,
        text:"Others",
        para:"Id - GIF0011",
        icon:<GoPlus/>
    },
    {
        img:other12,
        text:"Others",
        para:"Id - GIF0012",
        icon:<GoPlus/>
    },
    {
        img:other13,
        text:"Others",
        para:"Id - GIF0013",
        icon:<GoPlus/>
    },
    {
        img:other14,
        text:"Others",
        para:"Id - GIF0014",
        icon:<GoPlus/>
    },
    {
        img:other15,
        text:"Others",
        para:"Id - BAD001",
        icon:<GoPlus/>
    },
    {
        img:other16,
        text:"Others",
        para:"Id - BAD002",
        icon:<GoPlus/>
    },
    {
        img:other17,
        text:"Others",
        para:"Id - BAD003",
        icon:<GoPlus/>
    },
    {
        img:other18,
        text:"Others",
        para:"Id - BAD004",
        icon:<GoPlus/>
    },
    {
        img:other19,
        text:"Others",
        para:"Id - BAD005",
        icon:<GoPlus/>
    },
    {
        img:other20,
        text:"Others",
        para:"Id - BAD006",
        icon:<GoPlus/>
    },
    {
        img:other21,
        text:"Others",
        para:"Id - BAD007",
        icon:<GoPlus/>
    },
    {
        img:other22,
        text:"Others",
        para:"Id - BAD008",
        icon:<GoPlus/>
    },
    {
        img:other23,
        text:"Others",
        para:"Id - BAD009",
        icon:<GoPlus/>
    },
    {
        img:other24,
        text:"Others",
        para:"Id - BAD010",
        icon:<GoPlus/>
    },
    {
        img:other25,
        text:"Others",
        para:"Id - BAD011",
        icon:<GoPlus/>
    },
    {
        img:other26,
        text:"Others",
        para:"Id - BAD012",
        icon:<GoPlus/>
    },
    {
        img:other27,
        text:"Others",
        para:"Id - GIF007",
        icon:<GoPlus/>
    },
    {
        img:other28,
        text:"Others",
        para:"Id - BAD013",
        icon:<GoPlus/>
    },
    {
        img:other29,
        text:"Others",
        para:"Id - BAD014",
        icon:<GoPlus/>
    },
    {
        img:other30,
        text:"Others",
        para:"Id - BAD015",
        icon:<GoPlus/>
    },
    {
        img:other31,
        text:"Others",
        para:"Id - BAD016",
        icon:<GoPlus/>
    },
    {
        img:other32,
        text:"Others",
        para:"Id - BAD017",
        icon:<GoPlus/>
    },
    {
        img:other33,
        text:"Others",
        para:"Id - BAD018",
        icon:<GoPlus/>
    },
    {
        img:other34,
        text:"Others",
        para:"Id - BAD019",
        icon:<GoPlus/>
    },
    {
        img:other35,
        text:"Others",
        para:"Id - BAD020",
        icon:<GoPlus/>
    },
    {
        img:other36,
        text:"Others",
        para:"Id - BAD021",
        icon:<GoPlus/>
    },
    {
        img:other37,
        text:"Others",
        para:"Id - OTH000",
        icon:<GoPlus/>
    },
    {
        img:other38,
        text:"Others",
        para:"Id - OTH001",
        icon:<GoPlus/>
    },
    {
        img:other39,
        text:"Others",
        para:"Id - OTH002",
        icon:<GoPlus/>
    },
    {
        img:other40,
        text:"Others",
        para:"Id - OTH003",
        icon:<GoPlus/>
    },
    {
        img:other41,
        text:"Others",
        para:"Id - OTH004",
        icon:<GoPlus/>
    },
    {
        img:other42,
        text:"Others",
        para:"Id - OTH005",
        icon:<GoPlus/>
    },
    {
        img:other43,
        text:"Others",
        para:"Id - OTH006",
        icon:<GoPlus/>
    },
    {
        img:other44,
        text:"Others",
        para:"Id - OTH007",
        icon:<GoPlus/>
    },
    {
        img:other45,
        text:"Others",
        para:"Id - OTH008",
        icon:<GoPlus/>
    },
    {
        img:other46,
        text:"Others",
        para:"Id - OTH009",
        icon:<GoPlus/>
    },
    {
        img:other47,
        text:"Others",
        para:"Id - OTH010",
        icon:<GoPlus/>
    },
    {
        img:other48,
        text:"Others",
        para:"Id - OTH011",
        icon:<GoPlus/>
    },
    {
        img:other49,
        text:"Others",
        para:"Id - OTH012",
        icon:<GoPlus/>
    },
    {
        img:other50,
        text:"Others",
        para:"Id - OTH013",
        icon:<GoPlus/>
    },
    {
        img:other51,
        text:"Others",
        para:"Id - OTH014",
        icon:<GoPlus/>
    },
    {
        img:other52,
        text:"Others",
        para:"Id - OTH015",
        icon:<GoPlus/>
    },
    {
        img:other53,
        text:"Others",
        para:"Id - OTH016",
        icon:<GoPlus/>
    },
    {
        img:other54,
        text:"Others",
        para:"Id - OTH017",
        icon:<GoPlus/>
    },
    {
        img:other55,
        text:"Others",
        para:"Id - OTH018",
        icon:<GoPlus/>
    },
    {
        img:other56,
        text:"Others",
        para:"Id - OTH019",
        icon:<GoPlus/>
    },
    {
        img:other57,
        text:"Others",
        para:"Id - DIA001",
        icon:<GoPlus/>
    },
    {
        img:other58,
        text:"Others",
        para:"Id - DIA002",
        icon:<GoPlus/>
    },
    {
        img:other59,
        text:"Others",
        para:"Id - DIA003",
        icon:<GoPlus/>
    },
    {
        img:other60,
        text:"Others",
        para:"Id - DIA004",
        icon:<GoPlus/>
    },
    {
        img:other61,
        text:"Others",
        para:"Id - MUG001",
        icon:<GoPlus/>
    },
    {
        img:other62,
        text:"Others",
        para:"Id - MUG002",
        icon:<GoPlus/>
    },
    {
        img:other63,
        text:"Others",
        para:"Id - MUG003",
        icon:<GoPlus/>
    },
    {
        img:other64,
        text:"Others",
        para:"Id - MUG004",
        icon:<GoPlus/>
    },
    {
        img:other65,
        text:"Others",
        para:"Id - MUG005",
        icon:<GoPlus/>
    },
    {
        img:other66,
        text:"Others",
        para:"Id - MUG006",
        icon:<GoPlus/>
    },
    {
        img:other67,
        text:"Others",
        para:"Id - MUG007",
        icon:<GoPlus/>
    },
    {
        img:other68,
        text:"Others",
        para:"Id - MUG008",
        icon:<GoPlus/>
    },
    {
        img:other69,
        text:"Others",
        para:"Id - MUG009",
        icon:<GoPlus/>
    },
    {
        img:other70,
        text:"Others",
        para:"Id - MUG010",
        icon:<GoPlus/>
    },
    {
        img:other71,
        text:"Others",
        para:"Id - MUG011",
        icon:<GoPlus/>
    },
    {
        img:other72,
        text:"Others",
        para:"Id - MUG012",
        icon:<GoPlus/>
    },
    {
        img:other73,
        text:"Others",
        para:"Id - MUG013",
        icon:<GoPlus/>
    },
    {
        img:other74,
        text:"Others",
        para:"Id -  MUG014",
        icon:<GoPlus/>
    },
    {
        img:other75,
        text:"Others",
        para:"Id -  MUG015",
        icon:<GoPlus/>
    },
    {
        img:other76,
        text:"Others",
        para:"Id -  MUG016",
        icon:<GoPlus/>
    },
    {
        img:other77,
        text:"Others",
        para:"Id -  MUG017",
        icon:<GoPlus/>
    },
    {
        img:other78,
        text:"Others",
        para:"Id -  MUG018",
        icon:<GoPlus/>
    },
    {
        img:other79,
        text:"Others",
        para:"Id -  MUG019",
        icon:<GoPlus/>
    },
    {
        img:other80,
        text:"Others",
        para:"Id -  MUG020",
        icon:<GoPlus/>
    },
    {
        img:other81,
        text:"Others",
        para:"Id -  MUG021",
        icon:<GoPlus/>
    },
    {
        img:other82,
        text:"Others",
        para:"Id -  MUG022",
        icon:<GoPlus/>
    },
    {
        img:other83,
        text:"Others",
        para:"Id -  MUG023",
        icon:<GoPlus/>
    },
    {
        img:other84,
        text:"Others",
        para:"Id - MUG024",
        icon:<GoPlus/>
    },
    {
        img:other85,
        text:"Others",
        para:"Id - MUG025",
        icon:<GoPlus/>
    },
    {
        img:other86,
        text:"Others",
        para:"Id - MUG026",
        icon:<GoPlus/>
    },
    {
        img:other87,
        text:"Others",
        para:"Id - PEKE001",
        icon:<GoPlus/>
    },
    {
        img:other88,
        text:"Others",
        para:"Id - PEKE002",
        icon:<GoPlus/>
    },
    {
        img:other89,
        text:"Others",
        para:"Id - PEKE003",
        icon:<GoPlus/>
    },
    {
        img:other90,
        text:"Others",
        para:"Id - PEKE004",
        icon:<GoPlus/>
    },
    {
        img:other91,
        text:"Others",
        para:"Id - PEKE005",
        icon:<GoPlus/>
    },
    {
        img:other92,
        text:"Others",
        para:"Id - PEKE006",
        icon:<GoPlus/>
    },
    {
        img:other93,
        text:"Others",
        para:"Id - PEKE007",
        icon:<GoPlus/>
    },
    {
        img:other94,
        text:"Others",
        para:"Id - PEKE008",
        icon:<GoPlus/>
    },
    {
        img:other95,
        text:"Others",
        para:"Id - PEKE009",
        icon:<GoPlus/>
    },
    {
        img:other96,
        text:"Others",
        para:"Id - PEKE010",
        icon:<GoPlus/>
    },
    {
        img:other97,
        text:"Others",
        para:"Id - PEKE011",
        icon:<GoPlus/>
    },
    {
        img:other98,
        text:"Others",
        para:"Id - PEKE012",
        icon:<GoPlus/>
    },
    {
        img:other99,
        text:"Others",
        para:"Id - PEKE013",
        icon:<GoPlus/>
    },
    {
        img:other100,
        text:"Others",
        para:"Id - PEKE014",
        icon:<GoPlus/>
    },
    {
        img:other101,
        text:"Others",
        para:"Id - PEKE015",
        icon:<GoPlus/>
    },
    {
        img:other102,
        text:"Others",
        para:"Id - PEKE016",
        icon:<GoPlus/>
    },
    {
        img:other103,
        text:"Others",
        para:"Id - PEKE017",
        icon:<GoPlus/>
    },
    {
        img:other104,
        text:"Others",
        para:"Id - PEKE018",
        icon:<GoPlus/>
    },
    {
        img:other105,
        text:"Others",
        para:"Id - PEKE019",
        icon:<GoPlus/>
    },
    {
        img:other106,
        text:"Others",
        para:"Id - PEKE020",
        icon:<GoPlus/>
    },
    {
        img:other107,
        text:"Others",
        para:"Id - PEKE021",
        icon:<GoPlus/>
    },
    {
        img:other108,
        text:"Others",
        para:"Id - PEKE022",
        icon:<GoPlus/>
    },
    {
        img:other109,
        text:"Others",
        para:"Id - PEKE023",
        icon:<GoPlus/>
    },
    {
        img:other110,
        text:"Others",
        para:"Id - PEKE024",
        icon:<GoPlus/>
    },
    {
        img:other111,
        text:"Others",
        para:"Id - PEKE025",
        icon:<GoPlus/>
    },
    {
        img:other112,
        text:"Others",
        para:"Id - PEKE026",
        icon:<GoPlus/>
    },
    {
        img:other113,
        text:"Others",
        para:"Id - PEKE027",
        icon:<GoPlus/>
    },
    {
        img:other114,
        text:"Others",
        para:"Id - PEKE028",
        icon:<GoPlus/>
    },
    {
        img:other115,
        text:"Others",
        para:"Id - PEKE029",
        icon:<GoPlus/>
    },
    {
        img:other116,
        text:"Others",
        para:"Id - PEKE030",
        icon:<GoPlus/>
    }
]
export default other
