import React,{useEffect, useState} from 'react'
import { FiPhoneForwarded } from "react-icons/fi";
import brand from "../Assets/brands.png"
import logo from "../Assets/logo.png"
import { FaFacebookF } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
function Header() {
    const [Selected, SetSelected] = useState("Home");
    const navigate = useNavigate();
    function ChangePath(para1, para2) {
        navigate(para1)
        SetSelected(para2)
    }
    const [openSidebar, SetOpenSidebar] = useState()
    function SidebarFunction(param) {
        SetOpenSidebar(param)
    }

    useEffect(() => {
        navigate('/')
    }, []);

   const list=[
        {
            name:"Home",
            route: "/"
        },
        {
            name:"About Us",
            route: "/Aboutus"
        },
        {
            name:"Products",
            route: "/Products"
        },
        {
            name:"Achievements",
            route: "/Achievements"
        },
        {
            name:"Contact",
            route: "/Contactus"
        },
    ]
    return (
        <>
            <div className='flex justify-center items-center bg-secondary-100'>
                <div className='hidden md:flex max-w-[1300px] w-full justify-between px-5 paragraph'>
                    <div className='flex flex-row items-center gap-x-3'>
                        <div className='flex flex-row items-center gap-x-2'>
                            <FiPhoneForwarded />
                            <h1>Phone:</h1>
                            <h1>+91 4442064964</h1>
                        </div>
                        <div className='flex flex-row items-center gap-x-2'>
                            <FiPhoneForwarded />
                            <h1>Mail:</h1>   
                            <h1>mukesh@bombaygifts.net</h1>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center '>
                        <div>
                            <img src={brand} alt='' className=' h-20 w-20 p-2' />
                        </div>
                        <div className='flex flex-row gap-x-2'>
                            <FaFacebookF className='hover:text-primary-100 cursor-pointer duration-200' />
                            <RiLinkedinFill className='hover:text-primary-100 cursor-pointer duration-200' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='flex max-w-[1300px] w-full justify-between items-center p-5'>
                    <div className='w-[50%]'>
                    <img src={logo} alt='' className='w-[270px] h-[70px]' />
                    </div>
                    {list.map((item,index)=>
                    <div className={`hidden lg:flex flex-row justify-evenly items-center ${Selected === item.name ? 'text-primary-100  text-2xl font-serif' : 'text-black text-2xl font-serif'} text-black`} onClick={() => ChangePath(item.route, item.name)}>
                        <button>{item.name}</button>
                    </div>)}  
                    <div className='flex lg:hidden text-3xl' onClick={()=>SetOpenSidebar(true)}><FiMenu/></div>         
                </div>
            </div>
            {openSidebar == true ? <div className=''><Sidebar SidebarFunction={SidebarFunction} /></div> : null} 
        </>
    )
}

export default Header
