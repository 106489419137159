import travel1 from "../../Assets/travel1.jpg"
import travel2 from "../../Assets/travel2.jpg"
import travel3 from "../../Assets/travel3.jpg"
import travel4 from "../../Assets/travel4.jpg"
import travel5 from "../../Assets/travel5.jpg"
import travel6 from "../../Assets/travel6.jpg"
import travel7 from "../../Assets/travel7.jpg"
import travel8 from "../../Assets/travel8.jpg"
import travel9 from "../../Assets/travel9.jpg"
import travel10 from "../../Assets/travel10.jpg"
import travel11 from "../../Assets/travel11.jpg"
import travel12 from "../../Assets/travel12.jpg"
import travel13 from "../../Assets/travel13.jpg"
import travel14 from "../../Assets/travel14.jpg"
import travel15 from "../../Assets/travel15.jpg"
import travel16 from "../../Assets/travel16.jpg"
import travel17 from "../../Assets/travel17.jpg"
import travel18 from "../../Assets/travel18.jpg"
import travel19 from "../../Assets/travel19.jpg"
import travel20 from "../../Assets/travel20.jpg"
import travel21 from "../../Assets/travel21.jpg"
import travel22 from "../../Assets/travel22.jpg"
import { GoPlus } from "react-icons/go";
let travel=[
    {
        img:travel1,
        text:"Travel",
        para:"Id - TRO001",
        icon:<GoPlus/>
    },
    {
        img:travel2,
        text:"Travel",
        para:"Id - TRO002",
        icon:<GoPlus/>
    },
    {
        img:travel3,
        text:"Travel",
        para:"Id - TRO003",
        icon:<GoPlus/>
    },
    {
        img:travel4,
        text:"Travel",
        para:"Id - TRO004",
        icon:<GoPlus/>
    },
    {
        img:travel5,
        text:"Travel",
        para:"Id - TRO005",
        icon:<GoPlus/>
    },
    {
        img:travel6,
        text:"Travel",
        para:"Id - JAC001",
        icon:<GoPlus/>
    },
    {
        img:travel7,
        text:"Travel",
        para:"Id - JAC002",
        icon:<GoPlus/>
    },
    {
        img:travel8,
        text:"Travel",
        para:"Id - JAC003",
        icon:<GoPlus/>
    },
    {
        img:travel9,
        text:"Travel",
        para:"Id - JAC004",
        icon:<GoPlus/>
    },
    {
        img:travel10,
        text:"Travel",
        para:"Id - JAC005",
        icon:<GoPlus/>
    },
    {
        img:travel11,
        text:"Travel",
        para:"Id - JAC006",
        icon:<GoPlus/>
    },
    {
        img:travel12,
        text:"Travel",
        para:"Id - JAC007",
        icon:<GoPlus/>
    },
    {
        img:travel13,
        text:"Travel",
        para:"Id - JAC008",
        icon:<GoPlus/>
    },
    {
        img:travel14,
        text:"Travel",
        para:"Id - JAC009",
        icon:<GoPlus/>
    },
    {
        img:travel15,
        text:"Travel",
        para:"Id - JAC010",
        icon:<GoPlus/>
    },
    {
        img:travel16,
        text:"Travel",
        para:"Id - JAC011",
        icon:<GoPlus/>
    },
    {
        img:travel17,
        text:"Travel",
        para:"Id - JAC012",
        icon:<GoPlus/>
    },
    {
        img:travel18,
        text:"Travel",
        para:"Id - JAC013",
        icon:<GoPlus/>
    },
    {
        img:travel19,
        text:"Travel",
        para:"Id - JAC014",
        icon:<GoPlus/>
    },
    {
        img:travel20,
        text:"Travel",
        para:"Id - JAC015",
        icon:<GoPlus/>
    },
    {
        img:travel21,
        text:"Travel",
        para:"Id - JAC016",
        icon:<GoPlus/>
    },
    {
        img:travel22,
        text:"Travel",
        para:"Id - JAC017",
        icon:<GoPlus/>
    },
]
export default travel
