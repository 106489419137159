import handcraft1 from "../../Assets/handcraft1.jpg"
import handcraft2 from "../../Assets/handcraft2.jpg"
import handcraft3 from "../../Assets/handcraft3.jpg"
import handcraft4 from "../../Assets/handcraft4.jpg"
import handcraft5 from "../../Assets/handcraft5.jpg"
import handcraft6 from "../../Assets/handcraft6.jpg"
import handcraft7 from "../../Assets/handcraft7.jpg"
import handcraft8 from "../../Assets/handcraft8.jpg"
import handcraft9 from "../../Assets/handcraft9.jpg"
import handcraft10 from "../../Assets/handcraft10.jpg"
import handcraft11 from "../../Assets/handcraft11.jpg"
import handcraft12 from "../../Assets/handcraft12.jpg"
import handcraft13 from "../../Assets/handcraft13.jpg"
import handcraft14 from "../../Assets/handcraft14.jpg"
import handcraft15 from "../../Assets/handcraft15.jpg"
import handcraft16 from "../../Assets/handcraft16.jpg"
import handcraft17 from "../../Assets/handcraft17.jpg"
import handcraft18 from "../../Assets/handcraft18.jpg"
import handcraft19 from "../../Assets/handcraft19.jpg"
import handcraft20 from "../../Assets/handcraft20.jpg"
import handcraft21 from "../../Assets/handcraft21.jpg"
import handcraft22 from "../../Assets/handcraft22.jpg"
import handcraft23 from "../../Assets/handcraft23.jpg"
import handcraft24 from "../../Assets/handcraft24.jpg"
import handcraft25 from "../../Assets/handcraft25.jpg"
import handcraft26 from "../../Assets/handcraft26.jpg"
import handcraft27 from "../../Assets/handcraft27.jpg"
import handcraft28 from "../../Assets/handcraft28.jpg"
import handcraft29 from "../../Assets/handcraft29.jpg"
import handcraft30 from "../../Assets/handcraft30.jpg"
import handcraft31 from "../../Assets/handcraft31.jpg"
import handcraft32 from "../../Assets/handcraft32.jpg"
import handcraft33 from "../../Assets/handcraft33.jpg"
import handcraft34 from "../../Assets/handcraft34.jpg"
import handcraft35 from "../../Assets/handcraft35.jpg"
import handcraft36 from "../../Assets/handcraft36.jpg"
import handcraft37 from "../../Assets/handcraft37.jpg"
import handcraft38 from "../../Assets/handcraft38.jpg"
import handcraft39 from "../../Assets/handcraft39.jpg"
import handcraft40 from "../../Assets/handcraft40.jpg"
import { GoPlus } from "react-icons/go";
let handcraft=[
    {
        img:handcraft1,
        text:"Handicrafts",
        para:"Id -HAN001",
        icon:<GoPlus/>
    },
    {
        img:handcraft2,
        text:"Handicrafts",
        para:"Id -HAN002",
        icon:<GoPlus/>
    },
    {
        img:handcraft3,
        text:"Handicrafts",
        para:"Id -HAN003",
        icon:<GoPlus/>
    },
    {
        img:handcraft4,
        text:"Handicrafts",
        para:"Id -HAN004",
        icon:<GoPlus/>
    },
    {
        img:handcraft5,
        text:"Handicrafts",
        para:"Id -HAN005",
        icon:<GoPlus/>
    },
    {
        img:handcraft6,
        text:"Handicrafts",
        para:"Id -HAN006",
        icon:<GoPlus/>
    },
    {
        img:handcraft7,
        text:"Handicrafts",
        para:"Id -HAN007",
        icon:<GoPlus/>
    },
    {
        img:handcraft8,
        text:"Handicrafts",
        para:"Id -HAN008",
        icon:<GoPlus/>
    },
    {
        img:handcraft9,
        text:"Handicrafts",
        para:"Id -HAN009",
        icon:<GoPlus/>
    },
    {
        img:handcraft10,
        text:"Handicrafts",
        para:"Id -HAN010",
        icon:<GoPlus/>
    },
    {
        img:handcraft11,
        text:"Handicrafts",
        para:"Id -HAN011",
        icon:<GoPlus/>
    },
    {
        img:handcraft12,
        text:"Handicrafts",
        para:"Id -HAN012",
        icon:<GoPlus/>
    },
    {
        img:handcraft13,
        text:"Handicrafts",
        para:"Id -HAN013",
        icon:<GoPlus/>
    },
    {
        img:handcraft14,
        text:"Handicrafts",
        para:"Id -HAN014",
        icon:<GoPlus/>
    },
    {
        img:handcraft15,
        text:"Handicrafts",
        para:"Id -HAN015",
        icon:<GoPlus/>
    },
    {
        img:handcraft16,
        text:"Handicrafts",
        para:"Id -HAN016",
        icon:<GoPlus/>
    },
    {
        img:handcraft17,
        text:"Handicrafts",
        para:"Id -HAN017",
        icon:<GoPlus/>
    },
    {
        img:handcraft18,
        text:"Handicrafts",
        para:"Id -HAN018",
        icon:<GoPlus/>
    },
    {
        img:handcraft19,
        text:"Handicrafts",
        para:"Id -HAN019",
        icon:<GoPlus/>
    },
    {
        img:handcraft20,
        text:"Handicrafts",
        para:"Id -HAN020",
        icon:<GoPlus/>
    },
    {
        img:handcraft21,
        text:"Handicrafts",
        para:"Id -HAN021",
        icon:<GoPlus/>
    },
    {
        img:handcraft22,
        text:"Handicrafts",
        para:"Id -HAN022",
        icon:<GoPlus/>
    },
    {
        img:handcraft23,
        text:"Handicrafts",
        para:"Id -HAN023",
        icon:<GoPlus/>
    },
    {
        img:handcraft24,
        text:"Handicrafts",
        para:"Id -HAN024",
        icon:<GoPlus/>
    },
    {
        img:handcraft25,
        text:"Handicrafts",
        para:"Id -HAN025",
        icon:<GoPlus/>
    },
    {
        img:handcraft26,
        text:"Handicrafts",
        para:"Id -HAN026",
        icon:<GoPlus/>
    },
    {
        img:handcraft27,
        text:"Handicrafts",
        para:"Id -HAN027",
        icon:<GoPlus/>
    },
    {
        img:handcraft28,
        text:"Handicrafts",
        para:"Id -HAN028",
        icon:<GoPlus/>
    },
    {
        img:handcraft29,
        text:"Handicrafts",
        para:"Id -HAN029",
        icon:<GoPlus/>
    },
    {
        img:handcraft30,
        text:"Handicrafts",
        para:"Id -HAN030",
        icon:<GoPlus/>
    },
    {
        img:handcraft31,
        text:"Handicrafts",
        para:"Id -HAN031",
        icon:<GoPlus/>
    },
    {
        img:handcraft32,
        text:"Handicrafts",
        para:"Id -HAN032",
        icon:<GoPlus/>
    },
    {
        img:handcraft33,
        text:"Handicrafts",
        para:"Id -HAN033",
        icon:<GoPlus/>
    },
    {
        img:handcraft34,
        text:"Handicrafts",
        para:"Id -HAN034",
        icon:<GoPlus/>
    },
    {
        img:handcraft35,
        text:"Handicrafts",
        para:"Id -HAN035",
        icon:<GoPlus/>
    },
    {
        img:handcraft36,
        text:"Handicrafts",
        para:"Id -HAN036",
        icon:<GoPlus/>
    },
    {
        img:handcraft37,
        text:"Handicrafts",
        para:"Id -HAN037",
        icon:<GoPlus/>
    },
    {
        img:handcraft38,
        text:"Handicrafts",
        para:"Id -HAN038",
        icon:<GoPlus/>
    },
    {
        img:handcraft39,
        text:"Handicrafts",
        para:"Id -HAN039",
        icon:<GoPlus/>
    },
    {
        img:handcraft40,
        text:"Handicrafts",
        para:"Id -HAN040",
        icon:<GoPlus/>
    },
]
export default handcraft
