import tabletop1 from "../../Assets/tabletop1.jpg"
import tabletop2 from "../../Assets/tabletop2.jpg"
import tabletop3 from "../../Assets/tabletop3.jpg"
import tabletop4 from "../../Assets/tabletop4.jpg"
import tabletop5 from "../../Assets/tabletop5.jpg"
import tabletop6 from "../../Assets/tabletop6.jpg"
import tabletop7 from "../../Assets/tabletop7.jpg"
import tabletop8 from "../../Assets/tabletop8.jpg"
import tabletop9 from "../../Assets/tabletop9.jpg"
import tabletop10 from "../../Assets/tabletop10.jpg"
import tabletop11 from "../../Assets/tabletop11.jpg"
import tabletop12 from "../../Assets/tabletop12.jpg"
import tabletop13 from "../../Assets/tabletop13.jpg"
import tabletop14 from "../../Assets/tabletop14.jpg"
import tabletop15 from "../../Assets/tabletop15.jpg"
import tabletop16 from "../../Assets/tabletop16.jpg"
import tabletop17 from "../../Assets/tabletop17.jpg"
import tabletop18 from "../../Assets/tabletop18.jpg"
import tabletop19 from "../../Assets/tabletop19.jpg"
import tabletop20 from "../../Assets/tabletop20.jpg"
import tabletop21 from "../../Assets/tabletop21.jpg"
import tabletop22 from "../../Assets/tabletop22.jpg"
import tabletop23 from "../../Assets/tabletop23.jpg"
import tabletop24 from "../../Assets/tabletop24.jpg"
import tabletop25 from "../../Assets/tabletop25.jpg"
import tabletop26 from "../../Assets/tabletop26.jpg"
import tabletop27 from "../../Assets/tabletop27.jpg"
import tabletop28 from "../../Assets/tabletop28.jpg"
import tabletop29 from "../../Assets/tabletop29.jpg"
import tabletop30 from "../../Assets/tabletop30.jpg"
import tabletop31 from "../../Assets/tabletop31.jpg"
import tabletop32 from "../../Assets/tabletop32.jpg"
import tabletop33 from "../../Assets/tabletop33.jpg"
import tabletop34 from "../../Assets/tabletop34.jpg"
import tabletop35 from "../../Assets/tabletop35.jpg"
import tabletop36 from "../../Assets/tabletop36.jpg"
import tabletop37 from "../../Assets/tabletop37.jpg"
import tabletop38 from "../../Assets/tabletop38.jpg"
import tabletop39 from "../../Assets/tabletop39.jpg"
import tabletop40 from "../../Assets/tabletop40.jpg"
import tabletop41 from "../../Assets/tabletop41.jpg"
import tabletop42 from "../../Assets/tabletop42.jpg"
import { GoPlus } from "react-icons/go";
let tabletop=[
    {
        img:tabletop1,
        text:"Tabletop",
        para:"Id - ELD001",
        icon:<GoPlus/>
    },
    {
        img:tabletop2,
        text:"Tabletop",
        para:"Id - ELD002",
        icon:<GoPlus/>
    },
    {
        img:tabletop3,
        text:"Tabletop",
        para:"Id - ELD003",
        icon:<GoPlus/>
    },
    {
        img:tabletop4,
        text:"Tabletop",
        para:"Id - ELD004",
        icon:<GoPlus/>
    },
    {
        img:tabletop5,
        text:"Tabletop",
        para:"Id - ELD005",
        icon:<GoPlus/>
    },
    {
        img:tabletop6,
        text:"Tabletop",
        para:"Id - ELD006",
        icon:<GoPlus/>
    },
    {
        img:tabletop7,
        text:"Tabletop",
        para:"Id - ELD007",
        icon:<GoPlus/>
    },
    {
        img:tabletop8,
        text:"Tabletop",
        para:"Id - ELG001",
        icon:<GoPlus/>
    },
    {
        img:tabletop9,
        text:"Tabletop",
        para:"Id - ELG002",
        icon:<GoPlus/>
    },
    {
        img:tabletop10,
        text:"Tabletop",
        para:"Id - WMD001",
        icon:<GoPlus/>
    },
    {
        img:tabletop11,
        text:"Tabletop",
        para:"Id - WMD002",
        icon:<GoPlus/>
    },
    {
        img:tabletop12,
        text:"Tabletop",
        para:"Id - WMD003",
        icon:<GoPlus/>
    },
    {
        img:tabletop13,
        text:"Tabletop",
        para:"Id - WMD004",
        icon:<GoPlus/>
    },
    {
        img:tabletop14,
        text:"Tabletop",
        para:"Id - WMD005",
        icon:<GoPlus/>
    },
    {
        img:tabletop15,
        text:"Tabletop",
        para:"Id - WMD006",
        icon:<GoPlus/>
    },
    {
        img:tabletop16,
        text:"Tabletop",
        para:"Id - WMD007",
        icon:<GoPlus/>
    },
    {
        img:tabletop17,
        text:"Tabletop",
        para:"Id - WMD008",
        icon:<GoPlus/>
    },
    {
        img:tabletop18,
        text:"Tabletop",
        para:"Id - WMD009",
        icon:<GoPlus/>
    },
    {
        img:tabletop19,
        text:"Tabletop",
        para:"Id - WMD0010",
        icon:<GoPlus/>
    },
    {
        img:tabletop20,
        text:"Tabletop",
        para:"Id - WMD0011",
        icon:<GoPlus/>
    },
    {
        img:tabletop21,
        text:"Tabletop",
        para:"Id - WMD0012",
        icon:<GoPlus/>
    },
    {
        img:tabletop22,
        text:"Tabletop",
        para:"Id - WMD0013",
        icon:<GoPlus/>
    },
    {
        img:tabletop23,
        text:"Tabletop",
        para:"Id - WMD0014",
        icon:<GoPlus/>
    },
    {
        img:tabletop24,
        text:"Tabletop",
        para:"Id - WMD0015",
        icon:<GoPlus/>
    },
    {
        img:tabletop25,
        text:"Tabletop",
        para:"Id - WMD0016",
        icon:<GoPlus/>
    },
    {
        img:tabletop26,
        text:"Tabletop",
        para:"Id - WMD0017",
        icon:<GoPlus/>
    },
    {
        img:tabletop27,
        text:"Tabletop",
        para:"Id - WMD0018",
        icon:<GoPlus/>
    },
    {
        img:tabletop28,
        text:"Tabletop",
        para:"Id - WMD0019",
        icon:<GoPlus/>
    },
    {
        img:tabletop29,
        text:"Tabletop",
        para:"Id - WMD0020",
        icon:<GoPlus/>
    },
    {
        img:tabletop30,
        text:"Tabletop",
        para:"Id - WMD0021",
        icon:<GoPlus/>
    },
    {
        img:tabletop31,
        text:"Tabletop",
        para:"Id - WMD0022",
        icon:<GoPlus/>
    },
    {
        img:tabletop32,
        text:"Tabletop",
        para:"Id - WMD0023",
        icon:<GoPlus/>
    },
    {
        img:tabletop33,
        text:"Tabletop",
        para:"Id - WMD0024",
        icon:<GoPlus/>
    },
    {
        img:tabletop34,
        text:"Tabletop",
        para:"Id - WMD0025",
        icon:<GoPlus/>
    },
    {
        img:tabletop35,
        text:"Tabletop",
        para:"Id - WMD0026",
        icon:<GoPlus/>
    },
    {
        img:tabletop36,
        text:"Tabletop",
        para:"Id - WMD0027",
        icon:<GoPlus/>
    },
    {
        img:tabletop37,
        text:"Tabletop",
        para:"Id - WMD0028",
        icon:<GoPlus/>
    },
    {
        img:tabletop38,
        text:"Tabletop",
        para:"Id - WMD0029",
        icon:<GoPlus/>
    },
    {
        img:tabletop39,
        text:"Tabletop",
        para:"Id - WMD0030",
        icon:<GoPlus/>
    },
    {
        img:tabletop40,
        text:"Tabletop",
        para:"Id - WMD0031",
        icon:<GoPlus/>
    },
    {
        img:tabletop41,
        text:"Tabletop",
        para:"Id - WMD0032",
        icon:<GoPlus/>
    },
    {
        img:tabletop42,
        text:"Tabletop",
        para:"Id - WMD0033",
        icon:<GoPlus/>
    },
]
export default tabletop
