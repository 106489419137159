import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoPlus } from "react-icons/go";
import hometop1 from "../Assets/hometop1.jpg"
import hometop2 from "../Assets/hometop2.jpg"
import hometop3 from "../Assets/hometop3.jpg"
import hometop4 from "../Assets/hometop4.jpg"
import hometop5 from "../Assets/hometop5.jpg"
import hometop6 from "../Assets/hometop6.jpg"
import hometop7 from "../Assets/hometop7.jpg"
import hometop8 from "../Assets/hometop8.jpg"
import homep1 from "../Assets/homep1.jpg"
import homep2 from "../Assets/homep2.jpg"
import homep3 from "../Assets/homep3.jpg"
import homep4 from "../Assets/homep4.jpg"
import homep5 from "../Assets/homep5.jpg"
import homep6 from "../Assets/homep6.jpg"
import homep7 from "../Assets/homep7.jpg"
import { useNavigate } from "react-router-dom";
function Mainpage() {
  const navigate = useNavigate();
  let settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  const sliderlist = [
    {
      img: hometop1
    },
    {
      img: hometop2
    },
    {
      img: hometop3
    },
    {
      img: hometop4
    },
    {
      img: hometop5
    },
    {
      img: hometop6
    },
    {
      img: hometop7
    },
    {
      img: hometop8
    },
  ]
  const list = [
    {
      img: homep1,
      name: "Bags",
      text: "Bag | Umbrella",
      icon: <GoPlus />
    },
    {
      img: homep2,
      name: "Handicrafts",
      text: "Handicrafts",
      icon: <GoPlus />
    },
    {
      img: homep3,
      name: "Leather",
      text: "Leather Items | Folders",
      icon: <GoPlus />
    },
    {
      img: homep4,
      name: "Tabletop Display",
      text: "Electronic Desktop | Wooden & Metal desktop | Watch & Clock | Electronic Goods",
      icon: <GoPlus />
    },
    {
      img: homep5,
      name: "Tabletop Displays",
      text: "Electronic Desktop | Wooden & Metal desktop | Watch & Clock | Electronic Goods card Travel Trolley suitcase | Jacket and Rain coat",
      icon: <GoPlus />
    },
    {
      img: homep6,
      name: "Travel",
      text: "Trolley suitcase | Jacket and Rain coat card Trophies & Medals Acrlic | Brass | Crystal | Iseries | Wooden | Medals",
      icon: <GoPlus />
    },
    {
      img: homep7,
      name: "Others",
      text: "Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
      icon: <GoPlus />
    },
  ]
  const testimonials =[
    {
      para:"“Bombay gifts Products are reasonable prices and Various Styles..They Accept our Customization on their products based on our Needs. Look forward to the next cooperation, and I am happy to recommend Bombay Gifts to others”",
      name:"Divyabharathi, Vice President-HR, MNC, India."
    },
    {
      para:"“We really appreciate their attentiveness to customer service and unique product ranges and prompt delivery”",
      name:"Pramod khairnar, Admin-Head, Telecommunication Sector, India"
    },
    {
      para:"“All gifts are high quality in standards and its very suitable for prestigious Gifting Needs with Affordable prices. We feel really happy to associate with them”",
      name:"Kuldeepsingh, Director, Manufacturing Sector, India."
    },
  ]
  return (
    <>

      <div className='flex justify-center items-center bg-primary-100'>
        <div className='flex max-w-[1300px] w-full justify-center items-center px-5 py-2 '>
          <h1 className='topmainheading text-center'>Gifting Solutions: Bag| Diaries & Pens|Caps |Mugs| T-Shirts | Umberlla | Leather Items | Tabletops | Handicrafts | Travel | Trophies & Medals | More..</h1>
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <div className='flex max-w-[1300px] w-full justify-center items-center px-5 py-10 '>
          <div className='flex flex-col w-full '>
            <Slider {...settings}>
              {sliderlist.map((items, index) =>
                <div className='flex flex-row h-auto w-auto  '>
                  <img src={items.img} alt='' className='' />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center bg-thrid-100'>
        <div className='flex-1  max-w-[1300px] w-full gap-5 p-5 py-10 '>
          <div className='flex flex-col w-full gap-y-5'>
            <h1 className='textheading'>Welcome to Bombay Gifts</h1>
            <h1>We are the pioneer in the “Gifts & Novelties” industry in India and our presence more than 15+ years with 1000+ Satisfied Customers.</h1>
            <h1>We proud to say that, we won the Prestigious “Quality Brands India 2015-19” award and “National Industrial Excellence Award” from National Chamber of Commerce & Industry of India.</h1>
            <h1>We hold 1500+ products with 30+ different categories of gifting solutions. All our gifts are highly customized products based on the customer needs. Our Gifting solutions for the Corporate world are Bags, Caps, T-Shirts, Mugs, Diaries, Tabletop Displays, Leather Items & Folders, Trophies & Medals, Travel and Handicrafts Products.</h1>
            <h1>Explore our site for all your gifting solutions. We are happy to serve you better, reach us @ +91 44 42064964.</h1>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <div className='flex-1  max-w-[1300px] w-full gap-5 p-5 py-10 '>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-5'>
            {list.map((item, index) =>
              <div onClick={()=>navigate("/Products")}  className='flex flex-col items-center justify-center cursor-pointer hover:bg-primary-100 group relative overflow-hidden'>
                <img src={item.img} alt='' className='group-hover:scale-110  duration-500' />
                <div className='w-full h-full absolute group-hover:bg-primary-100/70 duration-500 top-0'></div>
                <div className='flex-col items-center text-center absolute group-hover:flex group-hover:duration-500 text-white hidden gap-2'>
                  <span className='subheading'>{item.name}</span>
                  <span className='text-sm'>{item.text}</span>
                  <span className='heading'>{item.icon}</span></div>
              </div>)}
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center bg-thrid-100'>
        <div className='flex-1  max-w-[1300px] w-full space-y-5 p-5 py-10 '>
        <h1 className='textheading'>Testimonials</h1>
          <div className='flex flex-col w-full bg-white p-5 '>
            <Slider {...settings}>
              {testimonials.map((items, index) =>
                <div className='flex flex-col h-full w-full space-y-5'>
                 <h1 className=''>{items.para}</h1>
                 <div className='flex justify-end font-semibold'>- {items.name}</div>
                </div>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export default Mainpage
