import Mainpage from "./Components/Mainpage";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Contactus from "./Components/Contactus";
import Achievements from "./Components/Achievements";
import Aboutus from "./Components/Aboutus";
import RoutingComponents from "./Components/RoutingComponents";

function App() {
  return (
   
    <RoutingComponents/>
   
  );  
}

export default App;
