import React from 'react'
import { TiRadar } from "react-icons/ti";
function Aboutus() {
    const list = [
        {
            text: "Bags",
        },
        {
            text: "Caps",
        },
        {
            text: "T-shirts",
        },
        {
            text: "Handicrafts",
        },
        {
            text: "Leather",
        },
        {
            text: "Tabletop Displays",
        },
        {
            text: "Travel",
        },
        {
            text: "Trophies & Medals",
        },
        {
            text: "Watches",
        },
        {
            text: "Others",
        },
    ]
    return (
        <>

            <div className='flex justify-center items-center bg-primary-100'>
                <div className='flex max-w-[1300px] w-full h-[70px] items-center px-5 '>
                    <h1 className='topheading'>About us</h1>
                </div>
            </div>
            <div className='flex justify-center items-center bg-thrid-100'>
                <div className='grid grid-cols-1 lg:grid-cols-2  max-w-[1300px] w-full gap-5 p-5 py-10 '>
                    <div className='flex flex-col w-full gap-y-5'>
                        <h1 className='textheading'>Who We Are</h1>
                        <h1>Bombay Gifts , we are one of the top and best Corporate gifting companies in India. We have“15 Years of Excellence” in the industry of “Gifts & Novelties” and offering staggering choices of high quality promotional products at competitive prices, breakneck turnaround times with complete reliability to our clients.</h1>
                        <h1>At Bombay Gifts, you’ll find the perfect gifts for the perfect occasions for all your business needs.</h1>
                        <h1>For our Service and Quality, we have received the Prestigious “Quality Brands India Award” and “National Industrial Excellence Award” from National Chamber of Commerce & Industry of India.</h1>
                        <h1>Our professionals are works deliberately to meet the customers’ expectations and their Satisfaction time-to-time to deliver the Best.</h1>
                        <h1>If you are looking for Traditional, Modern, Unique and cost-effective gift ideas that tie in with your business, you have come to the right place.</h1>
                    </div>
                    <div className='flex flex-col w-full gap-3'>
                        <h1 className='textheading'>Award & Accolades</h1>
                        {list.map((item, index) =>
                            <div className='flex flex-row '>
                                <TiRadar className='text-primary-100 text-xl' />
                                <h1 className='cursor-pointer hover:text-primary-100'>{item.text}</h1>
                            </div>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aboutus