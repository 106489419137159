import tshirts1 from "../../Assets/tshirts1.png"
import tshirts2 from "../../Assets/tshirts2.png"
import tshirts3 from "../../Assets/tshirts3.png"
import tshirts4 from "../../Assets/tshirts4.png"
import tshirts5 from "../../Assets/tshirts5.png"
import tshirts6 from "../../Assets/tshirts6.png"
import tshirts7 from "../../Assets/tshirts7.png"
import tshirts8 from "../../Assets/tshirts8.png"
import tshirts9 from "../../Assets/tshirts9.png"
import tshirts10 from "../../Assets/tshirts10.png"
import tshirts11 from "../../Assets/tshirts11.png"
import tshirts12 from "../../Assets/tshirts12.png"
import tshirts13 from "../../Assets/tshirts13.png"
import tshirts14 from "../../Assets/tshirts114.png"
import { GoPlus } from "react-icons/go";
let tshirts=[
    {
        img:tshirts1,
        text:"T-shirts",
        para:"Id - TSH001",
        icon:<GoPlus/>
    },
    {
        img:tshirts2,
        text:"T-shirts",
        para:"Id - TSH002",
        icon:<GoPlus/>
    },
    {
        img:tshirts3,
        text:"T-shirts",
        para:"Id - TSH003",
        icon:<GoPlus/>
    },
    {
        img:tshirts4,
        text:"T-shirts",
        para:"Id - TSH004",
        icon:<GoPlus/>
    },
    {
        img:tshirts5,
        text:"T-shirts",
        para:"Id - TSH005",
        icon:<GoPlus/>
    },
    {
        img:tshirts6,
        text:"T-shirts",
        para:"Id - TSH006",
        icon:<GoPlus/>
    },
    {
        img:tshirts7,
        text:"T-shirts",
        para:"Id - TSH007",
        icon:<GoPlus/>
    },
    {
        img:tshirts8,
        text:"T-shirts",
        para:"Id - TSH008",
        icon:<GoPlus/>
    },
    {
        img:tshirts9,
        text:"T-shirts",
        para:"Id - TSH009",
        icon:<GoPlus/>
    },
    {
        img:tshirts10,
        text:"T-shirts",
        para:"Id - TSH0010",
        icon:<GoPlus/>
    },
    {
        img:tshirts11,
        text:"T-shirts",
        para:"Id - TSH0011",
        icon:<GoPlus/>
    },
    {
        img:tshirts12,
        text:"T-shirts",
        para:"Id - TSH0012",
        icon:<GoPlus/>
    },
    {
        img:tshirts13,
        text:"T-shirts",
        para:"Id - TSH0013",
        icon:<GoPlus/>
    },
    {
        img:tshirts14,
        text:"T-shirts",
        para:"Id - TSH0014",
        icon:<GoPlus/>
    },
]
export default tshirts
