import Bag1 from "../../Assets/bag1.jpg"
import Bag2 from "../../Assets/bag2.jpg"
import Bag3 from "../../Assets/bag3.jpg"
import Bag4 from "../../Assets/bag4.jpg"
import Bag5 from "../../Assets/bag5.jpg"
import Bag6 from "../../Assets/bag6.jpg"
import Bag7 from "../../Assets/bag7.jpg"
import Bag8 from "../../Assets/bag8.jpg"
import Bag9 from "../../Assets/bag9.jpg"
import Bag10 from "../../Assets/bag10.jpg"
import Bag11 from "../../Assets/bag11.jpg"
import Bag12 from "../../Assets/bag21.jpg"
import Bag13 from "../../Assets/bag31.jpg"
import Bag14 from "../../Assets/bag14.jpg"
import Bag15 from "../../Assets/bag15.jpg"
import Bag16 from "../../Assets/bag16.jpg"
import Bag17 from "../../Assets/bag17.jpg"
import Bag18 from "../../Assets/bag18.jpg"
import Bag19 from "../../Assets/bag19.jpg"
import Bag20 from "../../Assets/bag20.jpg"
import Bag21 from "../../Assets/bag21.jpg"
import Bag22 from "../../Assets/bag22.jpg"
import Bag23 from "../../Assets/bag23.jpg"
import Bag24 from "../../Assets/bag24.jpg"
import Bag25 from "../../Assets/bag25.jpg"
import Bag26 from "../../Assets/bag26.jpg"
import Bag27 from "../../Assets/bag27.jpg"
import Bag28 from "../../Assets/bag28.jpg"
import Bag29 from "../../Assets/bag29.jpg"
import Bag30 from "../../Assets/bag30.jpg"
import Bag31 from "../../Assets/bag31.jpg"
import Bag32 from "../../Assets/bag32.jpg"
import Bag33 from "../../Assets/bag33.jpg"
import Bag34 from "../../Assets/bag34.jpg"
import Bag35 from "../../Assets/bag35.jpg"
import Bag36 from "../../Assets/bag36.jpg"
import Bag37 from "../../Assets/bag37.jpg"
import Bag38 from "../../Assets/bag38.jpg"
import Bag39 from "../../Assets/bag39.jpg"
import Bag40 from "../../Assets/bag10.jpg"
import Bag41 from "../../Assets/bag41.jpg"
import Bag42 from "../../Assets/bag42.jpg"
import Bag43 from "../../Assets/bag43.jpg"
import Bag44 from "../../Assets/bag44.jpg"
import Bag45 from "../../Assets/bag45.jpg"
import Bag46 from "../../Assets/bag46.jpg"
import Bag47 from "../../Assets/bag47.jpg"
import Bag48 from "../../Assets/bag48.jpg"
import Bag49 from "../../Assets/bag49.jpg"
import { GoPlus } from "react-icons/go";
let Bag=[
    {
        img:Bag1,
        text:"Bags",
        para:"Id - BAG001",
        icon:<GoPlus/>
    },
    {
        img:Bag2,
        text:"Bags",
        para:"Id - BAG002",
        icon:<GoPlus/>
    },
    {
        img:Bag3,
        text:"Bags",
        para:"Id - BAG003",
        icon:<GoPlus/>
    },
    {
        img:Bag4,
        text:"Bags",
        para:"Id - BAG004",
        icon:<GoPlus/>
    },
    {
        img:Bag5,
        text:"Bags",
        para:"Id - BAG005",
        icon:<GoPlus/>
    },
    {
        img:Bag6,
        text:"Bags",
        para:"Id - BAG006",
        icon:<GoPlus/>
    },
    {
        img:Bag7,
        text:"Bags",
        para:"Id - BAG007",
        icon:<GoPlus/>
    },
    {
        img:Bag8,
        text:"Bags",
        para:"Id - BAG008",
        icon:<GoPlus/>
    },
    {
        img:Bag9,
        text:"Bags",
        para:"Id - BAG009",
        icon:<GoPlus/>
    },
    {
        img:Bag10,
        text:"Bags",
        para:"Id - BAG0010",
        icon:<GoPlus/>
    },
    {
        img:Bag11,
        text:"Bags",
        para:"Id - BAG0011",
        icon:<GoPlus/>
    },
    {
        img:Bag12,
        text:"Bags",
        para:"Id - BAG0012",
        icon:<GoPlus/>
    },
    {
        img:Bag13,
        text:"Bags",
        para:"Id - BAG0013",
        icon:<GoPlus/>
    },
    {
        img:Bag14,
        text:"Bags",
        para:"Id - BAG0014",
        icon:<GoPlus/>
    },
    {
        img:Bag15,
        text:"Bags",
        para:"Id - BAG0015",
        icon:<GoPlus/>
    },
    {
        img:Bag16,
        text:"Bags",
        para:"Id - BAG0016",
        icon:<GoPlus/>
    },
    {
        img:Bag17,
        text:"Bags",
        para:"Id - BAG0017",
        icon:<GoPlus/>
    },
    {
        img:Bag18,
        text:"Bags",
        para:"Id - BAG0018",
        icon:<GoPlus/>
    },
    {
        img:Bag19,
        text:"Bags",
        para:"Id - BAG0019",
        icon:<GoPlus/>
    },
    {
        img:Bag20,
        text:"Bags",
        para:"Id - BAG0020",
        icon:<GoPlus/>
    },
    {
        img:Bag21,
        text:"Bags",
        para:"Id - BAG0021",
        icon:<GoPlus/>
    },
    {
        img:Bag22,
        text:"Bags",
        para:"Id - BAG0022",
        icon:<GoPlus/>
    },
    {
        img:Bag23,
        text:"Bags",
        para:"Id - BAG0023",
        icon:<GoPlus/>
    },
    {
        img:Bag24,
        text:"Bags",
        para:"Id - BAG0024",
        icon:<GoPlus/>
    },
    {
        img:Bag25,
        text:"Bags",
        para:"Id - BAG0025",
        icon:<GoPlus/>
    },
    {
        img:Bag26,
        text:"Bags",
        para:"Id - BAG0026",
        icon:<GoPlus/>
    },
    {
        img:Bag27,
        text:"Bags",
        para:"Id - BAG0027",
        icon:<GoPlus/>
    },
    {
        img:Bag28,
        text:"Bags",
        para:"Id - BAG0028",
        icon:<GoPlus/>
    },
    {
        img:Bag29,
        text:"Bags",
        para:"Id - BAG0029",
        icon:<GoPlus/>
    },
    {
        img:Bag30,
        text:"Bags",
        para:"Id - BAG0030",
        icon:<GoPlus/>
    },
    {
        img:Bag31,
        text:"Bags",
        para:"Id - BAG0031",
        icon:<GoPlus/>
    },
    {
        img:Bag32,
        text:"Bags",
        para:"Id - BAG0032",
        icon:<GoPlus/>
    },
    {
        img:Bag33,
        text:"Bags",
        para:"Id - BAG0033",
        icon:<GoPlus/>
    },
    {
        img:Bag34,
        text:"Bags",
        para:"Id - BAG0034",
        icon:<GoPlus/>
    },
    {
        img:Bag35,
        text:"Bags",
        para:"Id - BAG0035",
        icon:<GoPlus/>
    },
    {
        img:Bag36,
        text:"Bags",
        para:"Id - BAG0036",
        icon:<GoPlus/>
    },
    {
        img:Bag37,
        text:"Bags",
        para:"Id - BAG0037",
        icon:<GoPlus/>
    },
    {
        img:Bag38,
        text:"Bags",
        para:"Id - BAG0038",
        icon:<GoPlus/>
    },
    {
        img:Bag39,
        text:"Bags",
        para:"Id - BAG0039",
        icon:<GoPlus/>
    },
    {
        img:Bag40,
        text:"Bags",
        para:"Id - BAG0040",
        icon:<GoPlus/>
    },
    {
        img:Bag41,
        text:"Bags",
        para:"Id - BAG0041",
        icon:<GoPlus/>
    },
    {
        img:Bag42,
        text:"Bags",
        para:"Id - BAG0042",
        icon:<GoPlus/>
    },
    {
        img:Bag43,
        text:"Bags",
        para:"Id - BAG0043",
        icon:<GoPlus/>
    },
    {
        img:Bag44,
        text:"Bags",
        para:"Id - BAG0044",
        icon:<GoPlus/>
    },
    {
        img:Bag45,
        text:"Bags",
        para:"Id - BAG0045",
        icon:<GoPlus/>
    },
    {
        img:Bag46,
        text:"Bags",
        para:"Id - UMB001",
        icon:<GoPlus/>
    },
    {
        img:Bag47,
        text:"Bags",
        para:"Id - UMB002",
        icon:<GoPlus/>
    },
    {
        img:Bag48,
        text:"Bags",
        para:"Id - UMB003",
        icon:<GoPlus/>
    },
    {
        img:Bag49,
        text:"Bags",
        para:"Id - UMB004",
        icon:<GoPlus/>
    },
]
export default Bag
