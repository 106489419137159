import cap1 from "../../Assets/cap1.png"
import cap2 from "../../Assets/cap2.png"
import cap3 from "../../Assets/cap3.png"
import cap4 from "../../Assets/cap4.png"
import cap5 from "../../Assets/cap5.png"
import cap6 from "../../Assets/cap6.png"
import cap7 from "../../Assets/cap7.png"
import cap8 from "../../Assets/cap8.png"
import cap9 from "../../Assets/cap9.png"
import { GoPlus } from "react-icons/go";
let cap=[
    {
        img:cap1,
        text:"Caps",
        para:"Id - CAP001",
        icon:<GoPlus/>
    },
    {
        img:cap2,
        text:"Caps",
        para:"Id - CAP002",
        icon:<GoPlus/>
    },
    {
        img:cap3,
        text:"Caps",
        para:"Id - CAP003",
        icon:<GoPlus/>
    },
    {
        img:cap4,
        text:"Caps",
        para:"Id - CAP004",
        icon:<GoPlus/>
    },
    {
        img:cap5,
        text:"Caps",
        para:"Id - CAP005",
        icon:<GoPlus/>
    }, 
    {
        img:cap6,
        text:"Caps",
        para:"Id - CAP006",
        icon:<GoPlus/>
    },
    {
        img:cap7,
        text:"Caps",
        para:"Id - CAP007",
        icon:<GoPlus/>
    },
    {
        img:cap8,
        text:"Caps",
        para:"Id - CAP008",
        icon:<GoPlus/>
    },
    {
        img:cap9,
        text:"Caps",
        para:"Id - CAP009",
        icon:<GoPlus/>
    },
    
]
export default cap
