import all1 from "../../Assets/all1.jpg"
import all2 from "../../Assets/all2.jpg"
import all3 from "../../Assets/all3.jpg"
import all4 from "../../Assets/all4.jpg"
import all5 from "../../Assets/all5.jpg"
import all6 from "../../Assets/all6.jpg"
import all7 from "../../Assets/all7.jpg"
import all8 from "../../Assets/all8.jpg"
import all9 from "../../Assets/all9.jpg"
import all10 from "../../Assets/all10.jpg"
import all11 from "../../Assets/all11.jpg"
import all12 from "../../Assets/all12.jpg"
import all13 from "../../Assets/all13.jpg"
import all14 from "../../Assets/all14.jpg"
import all15 from "../../Assets/all15.jpg"
import all16 from "../../Assets/all16.jpg"
import all17 from "../../Assets/all17.jpg"
import all18 from "../../Assets/all18.jpg"
import all19 from "../../Assets/all19.jpg"
import all20 from "../../Assets/all20.jpg"
import all21 from "../../Assets/all21.jpg"
import all22 from "../../Assets/all22.jpg"
import all23 from "../../Assets/all23.jpg"
import all24 from "../../Assets/all24.jpg"
import { GoPlus } from "react-icons/go";
let All=[
    {
        img:all1,
        text:"All",
        para:"Bag | Umbrella",
        icon:<GoPlus/>
    },
    {
        img:all2,
        text:"All",     
        para:"Caps",
        icon:<GoPlus/>
    },
    {
        img:all3,
        text:"All",
        para:"Mugs",
        icon:<GoPlus/>
    },
    {
        img:all4,
        text:"All",
        para:"T-shirts",
        icon:<GoPlus/>
    },
    {
        img:all5,
        text:"All",
        para:"Leather Items | Folders",
        icon:<GoPlus/>
    },
    {
        img:all6,
        text:"All",
        para:"Leather Items | Folders",
        icon:<GoPlus/>
    },
    {
        img:all7,
        text:"All",
        para:"Leather Items | Folders",
        icon:<GoPlus/>
    },
    {
        img:all8,
        text:"All",
        para:"Leather Items | Folders",
        icon:<GoPlus/>
    },
    {
        img:all9,
        text:"All",
        para:"Electronic Desktop | Wooden & Metal desktop | Electronic Goods",
        icon:<GoPlus/>
    },
    {
        img:all10,
        text:"All",
        para:"Electronic Desktop | Wooden & Metal desktop | Electronic Goods",
        icon:<GoPlus/>
    },
    {
        img:all11,
        text:"All",
        para:"Electronic Desktop | Wooden & Metal desktop | Electronic Goods",
        icon:<GoPlus/>
    },
    {
        img:all12,
        text:"All",     
        para:"Watch & Clock",
        icon:<GoPlus/>
    },
    {
        img:all13,
        text:"All",
        para:"Trolley suitcase | Jacket and rain coat",
        icon:<GoPlus/>
    },
    {
        img:all14,
        text:"All",
        para:"Trolley suitcase | Jacket and rain coat",
        icon:<GoPlus/>
    },
    {
        img:all15,
        text:"All",
        para:"Trolley suitcase | Jacket and rain coat",
        icon:<GoPlus/>
    },
    {
        img:all16,
        text:"All",
        para:"Trolley suitcase | Jacket and rain coat",
        icon:<GoPlus/>
    },
    {
        img:all17,
        text:"All",
        para:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
        icon:<GoPlus/>
    },
    {
        img:all18,
        text:"All",
        para:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
        icon:<GoPlus/>
    },
    {
        img:all19,
        text:"All",
        para:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
        icon:<GoPlus/>
    },
    {
        img:all20,
        text:"All",
        para:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
        icon:<GoPlus/>
    },
    {
        img:all21,
        text:"All",
        para:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
        icon:<GoPlus/>
    },
    {
        img:all22,
        text:"All",
        para:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
        icon:<GoPlus/>
    },
    {
        img:all23,
        text:"All",
        para:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
        icon:<GoPlus/>
    },
    {
        img:all24,
        text:"All",
        para:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
        icon:<GoPlus/>
    },

]
export default All


